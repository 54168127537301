import { legacyDeserializerHandle } from './legacy';
import { deepEquals, fixNonSelfClosingIframes } from './utils/common-utils';
import { componentsSerializer } from './components-map';
import { componentsDeserializer } from './components-map';
export var deserialize = function (html, locale, parser) {
    var $ = parser || require('jquery');
    var runningInBrowser = function () { return (typeof window !== 'undefined' && window.document); };
    return deserializerHandle($)(!runningInBrowser() ? fixNonSelfClosingIframes(html) : html, locale);
};
export var deserializerHandle = function ($) { return function (html, locale) {
    return isValidHtmlContent($)(html, locale) ? deserializeHtmlToComponents($)(html, locale) : deserializeLegacyHtmlToComponents($)(html, locale);
}; };
export var serialize = function (components) {
    return serializeComponentsToHtml(components);
};
export var isValidHtmlContent = function ($) { return function (html, locale) {
    try {
        var parsedComponents = deserializeHtmlToComponents($)(html, locale);
        var reversedHtml = serializeComponentsToHtml(parsedComponents);
        var doubleParsedComponents = deserializeHtmlToComponents($)(reversedHtml, locale);
        var $children = $('<div>')
            .html(html)
            .children();
        var componentCount = $children
            .filter('div[data-component-type]')
            .length;
        var childrenCount = $children.length;
        var childrenCountMatch = childrenCount === parsedComponents.length;
        var componentCountMatch = componentCount === parsedComponents.length;
        var hasComponents = componentCount > 0;
        var componentContentMatch = deepEquals(parsedComponents, doubleParsedComponents);
        return hasComponents && childrenCountMatch && componentCountMatch && componentContentMatch;
    }
    catch (e) {
        // We don't rethrow, because if serialization / deserialization
        // above failed at some point it means that the component is not a valid one.
        return false;
    }
}; };
export var serializeComponentsToHtml = function (components) {
    return components
        .map(function (component) {
        var type = component.type;
        var value = component.value;
        var innerValue = componentsSerializer[type](value);
        return "<div data-component-type=\"" + type + "\">" + innerValue + "</div>";
    })
        .join('');
};
export var deserializeHtmlToComponents = function ($) { return function (html, locale) {
    var components = $(html)
        .filter('div[data-component-type]')
        .toArray()
        .map(function (node) {
        var $node = $(node);
        var templateId = $node.attr('data-template-id');
        var templatePartIndexString = $node.attr('data-template-part-index');
        var type = $node.attr('data-component-type');
        var componentData = {
            type: type,
            value: componentsDeserializer($, locale)[type]($node.contents())
        };
        if (templateId) {
            componentData.template = {
                id: templateId,
                index: parseInt(templatePartIndexString, 10)
            };
        }
        return componentData;
    });
    return components;
}; };
export var deserializeLegacyHtmlToComponents = function ($) { return function (legacyHtml, locale) {
    return legacyDeserializerHandle($)(legacyHtml, locale);
}; };
