import { deepEquals } from './utils/common-utils';
import { oldToNewComponentValueMap } from './v1-components-map';
import { v1ComponentsSerializationMap } from './v1-components-map';
import { legacyComponentsMapCreator } from './legacy-components-map';
export var consolidateTextComponents = function (components) { return components.reduce(function (combinedComponents, component) {
    var previous = combinedComponents[combinedComponents.length - 1];
    if (component.type === 'text' && previous && previous.type === 'text') {
        previous.value.html += component.value.html;
    }
    else {
        combinedComponents.push(component);
    }
    return combinedComponents;
}, []); };
var LegacySerializer = /** @class */ (function () {
    function LegacySerializer() {
    }
    LegacySerializer.isValidV1HtmlContent = function ($, html) {
        try {
            var parsedComponents = LegacySerializer.deserializeAsV1($, html);
            var reversedHtml = parsedComponents.map(function (component) {
                var innerValue = v1ComponentsSerializationMap($)[component.type].valueToHtml(component.value);
                return "<div data-component-type=\"" + component.type + "\">" + innerValue + "</div>";
            }).join('');
            var doubleParsedComponents = LegacySerializer.deserializeAsV1($, reversedHtml);
            var $children = $('<div>')
                .html(html)
                .children();
            var componentCount = $children
                .filter('div[data-component-type]')
                .length;
            var childrenCount = $children.length;
            var childrenCountMatch = childrenCount === parsedComponents.length;
            var componentCountMatch = componentCount === parsedComponents.length;
            var hasComponents = componentCount > 0;
            var componentContentMatch = deepEquals(parsedComponents, doubleParsedComponents);
            return hasComponents && childrenCountMatch && componentCountMatch && componentContentMatch;
        }
        catch (e) {
            // throw e;
            return false;
        }
    };
    LegacySerializer.deserializeAsV1 = function ($, oldVersionedHtml) {
        return $(oldVersionedHtml)
            .filter('div[data-component-type]')
            .toArray()
            .map(function (node) {
            var $node = $(node);
            var type = $node.attr('data-component-type');
            var componentData = {
                type: type,
                value: v1ComponentsSerializationMap($)[type].htmlNodeToValue($node.contents())
            };
            return componentData;
        });
    };
    LegacySerializer.deserializeAsLegacy = function ($, oldVersionedHtml) {
        var $node = $('<div class="dummy">').html(oldVersionedHtml);
        $node.find('iframe')
            .toArray()
            .reverse()
            .forEach(function (node) {
            var $iframe = $(node);
            var $parent = $iframe.parent();
            if (!$parent.is('.dummy')) {
                $iframe
                    .remove()
                    .insertAfter($parent);
            }
        });
        //unwrap step by step containers
        $node.find('.step-by-step-widget')
            .each(function () {
            /* tslint:disable */
            var $sbs = $(this);
            /* tslint:enable */
            while (!$sbs.parent().is('.dummy')) {
                $sbs.parent().replaceWith($sbs);
            }
        });
        var components = $node
            .children()
            .toArray()
            .map(function (child) {
            var $child = $(child);
            var innerVal = $('<div>').append($child.clone()).html();
            var result = {
                type: 'text',
                value: {
                    html: innerVal
                }
            };
            var found = false;
            var legacyComponentsMap = legacyComponentsMapCreator($);
            Object.keys(legacyComponentsMap).forEach(function (type) {
                var fn = legacyComponentsMap[type];
                var val = fn($child);
                if (found) {
                    return;
                }
                if (val) {
                    found = true;
                    result.type = type;
                    result.value = val;
                }
            });
            //migrate old kb-notes inside old
            if (result.type === 'text') {
                var oldNoteContent = $node.find('.kb-note').html();
                $node.find('.kb-note').removeClass('kb-note').addClass('note').html('<i>' + oldNoteContent + '</i>');
                result.value.html = $('<div>').append($child.clone()).html();
            }
            return result;
        });
        return consolidateTextComponents(components);
    };
    return LegacySerializer;
}());
export { LegacySerializer };
// export const LegacySerializ
export var legacyDeserializerHandle = function ($) { return function (oldVersionedHtml, locale) {
    var oldVersionedComponents;
    if (LegacySerializer.isValidV1HtmlContent($, oldVersionedHtml)) {
        oldVersionedComponents = LegacySerializer.deserializeAsV1($, oldVersionedHtml);
    }
    else {
        oldVersionedComponents = LegacySerializer.deserializeAsLegacy($, oldVersionedHtml);
    }
    return oldVersionedComponents.map(function (c) { return oldToNewComponentValueMap(locale, $)[c.type](c.value); });
}; };
// }
