export var legacyComponentsMapCreator = function ($) {
    return {
        table: function ($node) {
            if (!$node.is('table')) {
                return;
            }
            var headings = $node.find('tr:first')
                .find('th, td')
                .toArray()
                .map(function (data) {
                return $(data).html();
            });
            var rows = $node.find('tr:gt(0)')
                .toArray()
                .map(function (row) {
                return $(row).find('td').toArray().map(function (data) {
                    return $(data).html();
                });
            });
            return {
                columns: headings.length,
                rows: rows.length,
                headings: headings,
                cells: rows
            };
        },
        image: function ($node) {
            if ($node.is('img')) {
                return {
                    src: $node.attr('src'),
                    align: 'left',
                    link: ''
                };
            }
            else if ($node.children().length === 1 && $node.find('img').length === 1) {
                return {
                    src: $node.find('img').attr('src'),
                    align: 'left',
                    link: ''
                };
            }
            return;
        },
        iframe: function ($node) {
            if (!$node.is('iframe')) {
                return;
            }
            var src = $node.attr('src');
            var width = parseInt($node.css('width'), 10) || parseInt($node.attr('width'), 10) || 500;
            var height = parseInt($node.css('height'), 10) || parseInt($node.attr('height'), 10) || 500;
            var scrolling = $node.attr('scrolling') || 'auto';
            if (!src.match(/youtube.com/) && !src.match(/vimeo.com/)) {
                return {
                    url: src,
                    width: width,
                    height: height,
                    align: 'left',
                    scrolling: scrolling
                };
            }
            return;
        },
        video: function ($node) {
            if (!$node.is('iframe')) {
                return;
            }
            var width = parseInt($node.css('width'), 10) || parseInt($node.attr('width'), 10) || 500;
            var height = parseInt($node.css('height'), 10) || parseInt($node.attr('height'), 10) || 500;
            var src = $node.attr('src');
            if (src.match(/youtube.com/) || src.match(/vimeo.com/)) {
                return {
                    url: src,
                    width: width,
                    height: height,
                    align: 'left'
                };
            }
            return;
        },
        code: function (_$node) {
            //todo
        },
        anchor: function ($node) {
            if (!$node.is('.kb-anchor')) {
                return;
            }
            var text = $node.text();
            var id = $node.attr('id') || text.trim().replace(/['"#!.@\$,%\^\\&*?\(\)\[\]\-\+=:\/;\{\}~`]/ig, '').replace(/&nbsp;/g, '').replace(/\s/g, '-').substr(0, 25).toLowerCase() + (Math.random() * 200).toFixed(0);
            return {
                text: text,
                id: id
            };
        },
        note: function ($node) {
            if (!$node.is('.kb-note')) {
                return;
            }
            return {
                html: $node.html()
            };
        },
        tip: function ($node) {
            if (!$node.is('.kb-tip')) {
                return;
            }
            return {
                html: $node.html()
            };
        },
        important: function ($node) {
            if (!$node.is('.kb-important')) {
                return;
            }
            return {
                html: $node.html()
            };
        },
        'step-by-step': function ($node) {
            if (!$node.is('.step-by-step-widget')) {
                return;
            }
            var steps = $node.children('.step')
                .toArray()
                .map(function (step) {
                var $step = $(step);
                return {
                    html: $step.find('.description').html(),
                    imageSrc: $step.find('img').attr('src')
                };
            });
            return {
                steps: steps,
                layout: 'dots',
                allowCircular: $node.attr('data-allow-circular') === 'true'
            };
        },
    };
};
