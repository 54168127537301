import { removeExtraSpaces } from './utils/common-utils';
import { removeRedundantBoldTextAndSpacing } from './utils/common-utils';
import { informativeTypeToTitle } from './utils/informative-utils';
export var v1ComponentsSerializationMap = function ($) {
    return {
        text: {
            htmlNodeToValue: function ($node) {
                return {
                    html: $node.parent().html() || ''
                };
            },
            valueToHtml: function (value) {
                return value.html.replace(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/g, function (str) {
                    return str.replace(/	/g, ' &nbsp;');
                });
            }
        },
        tip: {
            htmlNodeToValue: function ($node) {
                return {
                    html: $('<div>').append($node).html()
                };
            },
            valueToHtml: function (value) {
                return value.html;
            }
        },
        important: {
            htmlNodeToValue: function ($node) {
                return {
                    html: $('<div>').append($node).html()
                };
            },
            valueToHtml: function (value) {
                return value.html;
            }
        },
        line: {
            htmlNodeToValue: function () {
                return {};
            },
            valueToHtml: function () { return '<hr />'; }
        },
        subheading: {
            htmlNodeToValue: function ($node) {
                return { text: $node.text() };
            },
            valueToHtml: function (value) { return "<h3>" + value.text + "</h3>"; }
        },
        note: {
            htmlNodeToValue: function ($node) {
                return {
                    html: $('<div>').append($node).html(),
                    type: 'note'
                };
            },
            valueToHtml: function (value) {
                return value.html;
            }
        },
        image: {
            htmlNodeToValue: function ($node) {
                var $img = $node.is('img') ? $node : $node.find('img');
                var $container = $node.is('.img-container') ? $node : $node.find('.img-container');
                var $link = $node.find('a');
                return {
                    src: $img.attr('src'),
                    alt: $img.attr('alt') || '',
                    link: $link.attr('href') || '',
                    align: $container.css('text-align') || 'left'
                };
            },
            valueToHtml: function (value) {
                var imageHtml = "<img src=\"" + value.src + "\" " + (value.alt ? 'alt="' + value.alt + '"' : '') + "/>";
                var innerHtml = imageHtml;
                if (value.link) {
                    innerHtml = "<a href=\"" + value.link + "\">" + innerHtml + "</a>";
                }
                return "<div class=\"img-container\" style=\"text-align:" + value.align + "\">" + innerHtml + "</div>";
            }
        },
        iframe: {
            htmlNodeToValue: function ($node) {
                var $iframe = $node.find('iframe');
                var width = ($iframe.css('width').indexOf('%') === -1) ? parseInt($iframe.css('width'), 10) : null;
                var height = ($iframe.css('height').indexOf('%') === -1) ? parseInt($iframe.css('height'), 10) : null;
                return {
                    url: $iframe.attr('src'),
                    width: width,
                    height: height,
                    align: $node.css('text-align'),
                    scrolling: $iframe.attr('scrolling') || 'auto'
                };
            },
            valueToHtml: function (value) {
                var width = value.width ? value.width + 'px' : '100%';
                var height = value.height ? value.height + 'px' : '100%';
                return "<div style=\"text-align:" + value.align + "\"><iframe src=\"" + value.url + "\" style=\"width:" + width + ";height:" + height + ";\" scrolling=\"" + value.scrolling + "\"/></div>";
            }
        },
        video: {
            htmlNodeToValue: function ($node) {
                var $iframe = $node.find('iframe');
                var width = ($iframe.css('width').indexOf('%') === -1) ? parseInt($iframe.css('width'), 10) : null;
                var height = ($iframe.css('height').indexOf('%') === -1) ? parseInt($iframe.css('height'), 10) : null;
                return {
                    url: $iframe.attr('src'),
                    width: width,
                    height: height,
                    align: $node.css('text-align')
                };
            },
            valueToHtml: function (value) {
                var width = value.width ? value.width + 'px' : '100%';
                var height = value.height ? value.height + 'px' : '100%';
                return "<div style=\"text-align:" + value.align + "\"><iframe src=\"" + value.url + "\" style=\"width:" + width + ";height:" + height + ";\"></iframe></div>";
            }
        },
        anchor: {
            htmlNodeToValue: function ($node) {
                return { text: $node.text(), id: $node.attr('id') || '' };
            },
            valueToHtml: function (value) {
                var id = value.id || '';
                return "<h2 class=\"anchor-title\" id=\"" + id + "\">" + value.text + "</h2>";
            }
        },
        code: {
            htmlNodeToValue: function ($node) {
                return {
                    language: $node.attr('data-language'),
                    lineNumbers: !!$node.attr('data-line-numbers'),
                    lineWrapping: !!$node.attr('data-line-wrapping'),
                    code: $node.find('code').text()
                };
            },
            valueToHtml: function (value) {
                return "<div class=\"code-snippet\" data-language=\"" + value.language + "\" data-line-numbers=\"" + value.lineNumbers + "\" data-line-wrapping=\"" + value.lineWrapping + "\"><pre><code>" + value.code + "</code></pre></div>";
            }
        },
        table: {
            htmlNodeToValue: function ($node) {
                return {
                    rows: $node.find('tbody tr').length,
                    columns: $node.find('th').length,
                    headings: $node.find('th').toArray().map(function (elm) {
                        return $(elm).text();
                    }),
                    cells: $node
                        .find('tbody tr')
                        .toArray()
                        .map(function (elm) { return $(elm).find('td')
                        .toArray()
                        .map(function (_elm) { return $(_elm).html(); }); })
                };
            },
            valueToHtml: function (value) {
                var headContent = '', bodyContent = '';
                for (var col = 0; col < value.columns; col++) {
                    headContent += "<th>" + value.headings[col] + "</th>";
                }
                for (var row = 0; row < value.rows; row++) {
                    bodyContent += '<tr>';
                    for (var col = 0; col < value.columns; col++) {
                        bodyContent += "<td>" + (value.cells[row] && value.cells[row][col] ? value.cells[row][col] : '') + "</td>";
                    }
                    bodyContent += '</tr>';
                }
                return "<table><thead><tr>" + headContent + "</tr><tbody>" + bodyContent + "</tbody></table>";
            }
        },
        'step-by-step': {
            htmlNodeToValue: function ($node) {
                var steps = [];
                steps =
                    $node.find('.step')
                        .toArray()
                        .map(function (step) {
                        var $step = $(step);
                        var $img = $step.find('.step-image');
                        var $desc = $step.find('.step-description');
                        return {
                            html: $desc.html(),
                            imageSrc: $img.attr('src')
                        };
                    });
                return {
                    layout: $node.attr('data-layout') || 'newspaper',
                    allowCircular: $node.attr('data-allow-circular') === 'true',
                    steps: steps
                };
            },
            valueToHtml: function (stepsValue) {
                var steps = stepsValue.steps;
                var stepsHtml = steps.map(function (step) {
                    return "<section class=\"step\"><img class=\"step-image\" src=\"" + step.imageSrc + "\" /><div class=\"step-description\">" + step.html + "</div></section>";
                }).join('');
                stepsValue.layout = stepsValue.layout || 'newspaper';
                return "<div class=\"step-by-step-container\" data-layout=\"" + stepsValue.layout + "\" data-allow-circular=\"" + stepsValue.allowCircular + "\">" + stepsHtml + "</div>";
            }
        }
    };
};
export var oldToNewComponentValueMap = function (locale, $) {
    return {
        text: function (oldValue) {
            return {
                type: 'text',
                value: {
                    html: removeExtraSpaces(oldValue.html, $)
                }
            };
        },
        tip: function (oldValue) {
            return {
                type: 'informative',
                value: {
                    color: 'green',
                    title: informativeTypeToTitle('tip', locale),
                    html: removeRedundantBoldTextAndSpacing(oldValue.html, $)
                }
            };
        },
        important: function (oldValue) {
            return {
                type: 'informative',
                value: {
                    color: 'orange',
                    title: informativeTypeToTitle('important', locale),
                    html: removeRedundantBoldTextAndSpacing(oldValue.html, $)
                }
            };
        },
        line: function () {
            return {
                type: 'line',
                value: {
                    style: 'thin'
                }
            };
        },
        subheading: function (oldValue) {
            return {
                type: 'heading',
                value: {
                    text: oldValue.text,
                    anchorId: ''
                }
            };
        },
        note: function (oldValue) {
            return {
                type: 'text',
                value: {
                    html: removeExtraSpaces(oldValue.html, $)
                }
            };
        },
        image: function (oldValue) {
            return {
                type: 'image',
                value: oldValue
            };
        },
        iframe: function (oldValue) {
            return {
                type: 'iframe',
                value: {
                    url: oldValue.url,
                    width: 600,
                    height: 400,
                    align: 'center',
                    scrolling: oldValue.scrolling
                }
            };
        },
        video: function (oldValue) {
            return {
                type: 'video',
                value: {
                    url: oldValue.url,
                    width: oldValue.width,
                    height: oldValue.height,
                    align: 'center'
                }
            };
        },
        table: function (oldValue) {
            return {
                type: 'table',
                value: oldValue
            };
        },
        code: function (oldValue) {
            return {
                type: 'code',
                value: {
                    language: oldValue.language,
                    code: oldValue.code,
                    lineNumbers: true,
                    lineWrapping: true
                }
            };
        },
        anchor: function (oldValue) {
            return {
                type: 'heading',
                value: {
                    text: oldValue.text,
                    anchorId: oldValue.id
                }
            };
        },
        'step-by-step': function (oldValue) {
            return {
                type: 'step-by-step',
                value: {
                    allowCircular: oldValue.allowCircular,
                    layout: 'vertical',
                    steps: oldValue.steps.map(function (oldStep) {
                        return {
                            html: oldStep.html,
                            title: '',
                            image: {
                                src: oldStep.imageSrc,
                                alt: '',
                                link: ''
                            }
                        };
                    })
                }
            };
        }
    };
};
