import { componentTranslations } from './component-translations';
export var infromativeTypeToColor = function (type) {
    switch (type) {
        case 'tip': return 'green';
        case 'warning': return 'red';
        case 'important': return 'orange';
        case 'note': return 'blue';
        default: return 'green';
    }
};
export var informativeTypeToTitle = function (type, locale) {
    return componentTranslations[locale][type];
};
