export var componentTranslations = {
    cs: {
        important: 'Important:',
        note: 'Note:',
        tip: 'Spropitné:',
        warning: 'Warning:'
    },
    da: {
        important: 'Important',
        note: 'Note',
        tip: 'Tip',
        warning: 'Warning'
    },
    de: {
        important: 'Wichtig:',
        note: 'Hinweis:',
        tip: 'Tipp:',
        warning: 'Warnung:',
    },
    en: {
        important: 'Important:',
        note: 'Note:',
        tip: 'Tip:',
        warning: 'Warning:',
    },
    es: {
        important: 'Importante:',
        note: 'Nota:',
        tip: 'Consejo:',
        warning: 'Advertencia:',
    },
    fr: {
        important: 'Important :',
        note: 'Remarque :',
        tip: 'Suggestion :',
        warning: 'Avertissement :',
    },
    he: {
        important: 'Important',
        note: 'Note',
        tip: 'טיפ:',
        warning: 'Warning',
    },
    hi: {
        important: 'महत्वपूर्ण:',
        note: 'नोट:',
        tip: 'युक्ति:',
        warning: 'चेतावनी:',
    },
    it: {
        important: 'Importante:',
        note: 'Nota:',
        tip: 'Suggerimento:',
        warning: 'Attenzione:',
    },
    ja: {
        important: '重要：',
        note: '注意:',
        tip: 'ヒント：',
        warning: '警告：',
    },
    ko: {
        important: '중요!',
        note: '참고:',
        tip: '도움말:',
        warning: '주의사항:',
    },
    nl: {
        important: 'Belangrijk:',
        note: 'Opmerking:',
        tip: 'Tip:',
        warning: 'Waarschuwing:',
    },
    no: {
        important: 'Viktig:',
        note: 'Mer:',
        tip: 'Tips:',
        warning: 'Advarsel:',
    },
    pl: {
        important: 'Ważne:',
        note: 'Notatka:',
        tip: 'Porada:',
        warning: 'Warning',
    },
    pt: {
        important: 'Importante:',
        note: 'Nota:',
        tip: 'Dica:',
        warning: 'Atenção:',
    },
    ru: {
        important: 'Важно:',
        note: 'Примечание:',
        tip: 'Совет:',
        warning: 'Обратите внимание:',
    },
    sv: {
        important: 'Viktigt',
        note: 'Note',
        tip: 'Tips',
        warning: 'Warning',
    },
    tr: {
        important: 'Important',
        note: 'Note',
        tip: 'Tip',
        warning: 'Warning',
    },
    zh: {
        important: 'Important',
        note: 'Note',
        tip: 'Tip',
        warning: 'Warning',
    }
};
