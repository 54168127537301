export var fixDoubleSpacesInHtml = function (html) { return html && html.replace && html.replace(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/g, function (str) {
    return str.replace(/  /g, ' &nbsp;');
}); };
export var deepEquals = require('deep-equal');
export var removeRedundantBoldTextAndSpacing = function (html, parser) {
    var $ = parser || require('jquery');
    var dummyDiv = $('<div>').html(html);
    var text = dummyDiv.text();
    var firstWord = text.trim().split(' ')[0].trim();
    var boldNode = dummyDiv.find('b, strong').first();
    if (firstWord.indexOf(boldNode.text().replace(':', '').trim()) > -1) {
        boldNode.remove();
    }
    // Weird way to remove stray ':' that remain from content migration. Should probably do this differently.
    if (dummyDiv.text().trim()[0] === ':') {
        dummyDiv.html(dummyDiv.html().replace(':', ''));
    }
    dummyDiv.children().each(function (_i, e) {
        var elem = $(e);
        if (elem.text().trim().length === 0) {
            elem.remove();
        }
    });
    dummyDiv.html(removeExtraSpaces(dummyDiv.html(), $));
    return dummyDiv.html().trim();
};
export var removeExtraSpaces = function (html, parser) {
    var $ = parser || require('jquery');
    var dummyDiv = $('<div>').html(html);
    dummyDiv.find('p').each(function () {
        /* tslint:disable */
        var $this = $(this);
        /* tslint:enable */
        if ($this.html().replace(/\s|&nbsp;/g, '').length === 0) {
            $this.remove();
        }
        else {
            $this.html($this.html().trim());
        }
    });
    return dummyDiv.html().trim();
};
export var fixNonSelfClosingIframes = function (html) {
    return html.replace(/<iframe([\s\S][^>]*)\/>/gm, '<iframe$1></iframe>');
};
export var convertTrixImagesToSimpleImgTags = function (html, parser) {
    var $ = parser || require('jquery');
    var $node = $('<div></div>').html(html);
    var TRIX_ATTACHMENT_SELECTOR = '[data-trix-attachment]';
    var TRIX_IMAGE_CONTENT_TYPE = 'image';
    var TRIX_FIGURE_ELEMENT_SELECTOR = 'figure.attachment.attachment-preview';
    $node.find(TRIX_ATTACHMENT_SELECTOR).each(function (_, el) {
        var trixImage = $(el);
        if (trixImage.data().trixContentType === TRIX_IMAGE_CONTENT_TYPE) {
            var url = trixImage.data().trixAttachment.url;
            var img = "<img src=" + url + ">";
            var maybeLinkParent = trixImage.closest('a');
            if (maybeLinkParent.length) {
                // If the image has a link attached, the anchor tag is already formatted as we want it
                // so we just replace it's content with a simple image tag
                maybeLinkParent.html(img);
            }
            else {
                // Otherwise we want to replace the entire <figure> element surrounding the image
                // as well as the two pseudo elements around it used by trix for handling focus
                var figureParent = trixImage.closest(TRIX_FIGURE_ELEMENT_SELECTOR);
                figureParent.next().remove();
                figureParent.prev().remove();
                figureParent.replaceWith(img);
            }
        }
    });
    return $node.html();
};
