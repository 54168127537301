var Entities = require('html-entities/lib/xml-entities');
var ContentEditorEntityRenderer = /** @class */ (function () {
    function ContentEditorEntityRenderer() {
    }
    ContentEditorEntityRenderer.encode = function (text) {
        var entities = new Entities();
        return entities.encode(text);
    };
    ContentEditorEntityRenderer.decode = function (text) {
        var entities = new Entities();
        return entities.decode(text);
    };
    return ContentEditorEntityRenderer;
}());
export { ContentEditorEntityRenderer };
export var encodeEntity = function (stringToEncode) {
    return ContentEditorEntityRenderer.encode(stringToEncode);
};
export var decodeEntity = function (stringToDecode) {
    return ContentEditorEntityRenderer.decode(stringToDecode);
};
